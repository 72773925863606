import React, { useContext } from 'react';
import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ContentBlock from 'components/Content/ContentBlock';

import DownloadCopayCard from '../../../codes/components/callout-contents/nspat/DownloadCopayCard';
import DownloadPatientBrochure from '../../../codes/components/callout-contents/nspat/DownloadPatientBrochure';

// import page style
import './patient-resources.scss';

// Images
import patResourceHCPHeroMobile from '../../../assets/images/NS-HCP-Hero-PatResources-mobile.png';

// PDFs

const PatientResourcesPage = () => {
  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.hcp;
  const metaData = appConfigs?.metaData?.hcp;

  // const brandNames = {
  //   NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
  //   BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
  //   NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  // };

  const pageTitle = 'NASCOBAL® | Patient Resources';
  const pageDescription = 'Download resources for appropriate patients';

  const renderPage = () => {
    return (
      <div id="nascobal-hcp-patient-resources">
        <ContentBlock id="patient-resources-block">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <Row>
              <Col xs={12}>
                <div id="hero" className="hide-in-small">
                  <h2>Patient Resources</h2>
                </div>
                <div id="hero-mobile" className="show-in-small">
                  <img src={patResourceHCPHeroMobile} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <DownloadCopayCard />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <DownloadPatientBrochure />
              </Col>
            </Row>
          </Grid>
        </ContentBlock>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="hcp-patient-resources">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default PatientResourcesPage;
