import React, { useContext } from 'react';
import { Callout } from 'components';
// import { Row, Col } from 'react-flexbox-grid';
import { appContext } from 'providers/appProvider';
import DownloadBlock from 'components/DownloadBlock';
import ColorBlock from 'components/Content/ColorBlock';

function DownloadPatientBrochure({ className, ...props }) {
  const { appConfigs } = useContext(appContext);
  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };
  return (
    <Callout className={`${className}`}>
      <ColorBlock blue id="colorblock" className="mt-15 mb-20">
        <DownloadBlock
          DownloadFile="NS-05830_NS_Patient_Brochure_Digital_CLEAN.pdf"
          LinkCaption="DOWNLOAD BROCHURE"
          ThumbnailImage="ns-pat-brochure-thumb.png"
          secondary
          secondaryDownloadFile="NS-05829_Nascobal_Spanish_Patient_Brochure_Digital_CLEAN.pdf"
          secondaryLinkCaption="DOWNLOAD SPANISH BROCHURE"
          className="ns-brochure-btn blue-button"
          secondaryClassName="ns-brochure-btn-spanish blue-button"
        >
          <h2 className="txt-uppercase">PATIENT BROCHURE</h2>
          <p>
            Download the{' '}
            <span
              className="brandname-nascobal"
              dangerouslySetInnerHTML={{
                __html: brandNames.NASCOBAL,
              }}
            />{' '}
            Patient Brochure to learn more about treating vitamin B
            <sub className="vitaminb12-sub">12</sub> deficiency with{' '}
            <span
              className="brandname-nascobal"
              dangerouslySetInnerHTML={{
                __html: brandNames.NASCOBAL,
              }}
            />{' '}
            Nasal Spray.
          </p>
        </DownloadBlock>
      </ColorBlock>
    </Callout>
  );
}

export default DownloadPatientBrochure;
